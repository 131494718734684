import { Button, Dropdown } from "antd"
import React, { useEffect, useState } from "react"

import style from "./headerDropdown.module.scss"
import { layoutLang } from "../../../../../utils/language/layout"
import { RU, USD, UZ, UZS } from "../../../../../utils/constants/locales"
import ArrowBottom from "../../../../../assets/icons/ArrowBottom"
import { useRouter } from "next/router"
import { useAppDispatch, useAppSelector } from "../../../../../hooks/reduxHooks"
import { localeReducerActions } from "../../../../../store/reducers/localeReducer"

const HeaderDropDown = () => {
  const dispatch = useAppDispatch()
  const router = useRouter()
  const { lang, currency } = useAppSelector((state) => state.localeReducer)
  const { changeLanguage, changeCurrency, changeUsdValue } =
    localeReducerActions
  const [visible, setVisible] = useState(false)
  const [langValue, setLangValue] = useState("")
  const [currencyValue, setCurrencyValue] = useState(currency)

  useEffect(() => {
    fetch(`https://cbu.uz/ru/arkhiv-kursov-valyut/json/`)
      .then((res) => res.json())
      .then((res: any[]) => {
        dispatch(changeUsdValue(res?.find((item) => item?.Ccy === "USD")?.Rate))
      })

    dispatch(changeLanguage(router?.locale === RU ? RU : UZ))
  }, [])

  useEffect(() => {
    setLangValue(lang)
  }, [lang])

  const handleChangeLang = (lang: string) => {
    setLangValue(lang)
  }

  const handleChangeCurrency = (currency: string) => {
    setCurrencyValue(currency)
  }

  const handleRoute = () =>
    router.push(router.asPath, router.asPath, { locale: lang })

  const handleSave = () => {
    dispatch(changeCurrency(currencyValue))
    handleChangeLang(lang)
    handleRoute()
    setTimeout(() => {
      setVisible(false)
    }, 800)
  }

  return (
    <Dropdown
      placement="bottomRight"
      visible={visible}
      overlay={
        <div className={style.dropDown} onMouseLeave={handleSave}>
          <div className={style.dropDown_top}>
            <div className={style.dropDown_item}>
              <label>{layoutLang[String(router?.locale)]["Til"]}</label>
              <div className={style.dropDown_item_elem}>
                <span
                  style={{
                    backgroundColor: lang === UZ ? "#0DC683CC" : "",
                    color: lang === UZ ? "#fff" : "",
                  }}
                  onClick={() => dispatch(changeLanguage(UZ))}
                >
                  O’zbekcha
                </span>
                <span
                  style={{
                    backgroundColor: lang === RU ? "#0DC683CC" : "",
                    color: lang === RU ? "#fff" : "",
                  }}
                  onClick={() => dispatch(changeLanguage(RU))}
                >
                  Русский
                </span>
              </div>
            </div>
            <div className={style.line}></div>
            <div className={style.dropDown_item}>
              <label>{layoutLang[String(router?.locale)]["Valyuta"]}</label>
              <div className={style.dropDown_item_elem}>
                <span
                  style={{
                    backgroundColor: currencyValue === UZS ? "#0DC683CC" : "",
                    color: currencyValue === UZS ? "#fff" : "",
                  }}
                  onClick={() => handleChangeCurrency(UZS)}
                >
                  UZS
                </span>
                <span
                  style={{
                    backgroundColor: currencyValue === USD ? "#0DC683CC" : "",
                    color: currencyValue === USD ? "#fff" : "",
                  }}
                  onClick={() => handleChangeCurrency(USD)}
                >
                  USD
                </span>
              </div>
            </div>
          </div>
          <Button type="primary" onClick={handleSave}>
            {layoutLang[String(router?.locale)]["Saqlash"]}
          </Button>
        </div>
      }
      trigger={["click", "hover"]}
      mouseEnterDelay={1000}
      mouseLeaveDelay={0.3}
      overlayClassName={style.overlayClassDrop}
    >
      <div className={style.dropDownChildren} onClick={() => setVisible(true)}>
        <span>{langValue}</span>
        <div className={style.line}></div>
        <span>{currency}</span>
        <ArrowBottom />
      </div>
    </Dropdown>
  )
}

export default HeaderDropDown
