import { useEffect, useMemo, useRef, useState } from "react"
import { LoadingOutlined } from "@ant-design/icons"
import { Popover, Spin, Tooltip } from "antd"
import { useTranslations } from "next-intl"
import { useRouter } from "next/router"
import { useAppDispatch, useAppSelector } from "../../../../../hooks/reduxHooks"
import { userReducerActions } from "../../../../../store/reducers/userReducer"
import { eventTypes } from "../../../../../utils/constants/eventTypes"
import { PHONE_MASK } from "../../../../../utils/constants/inputMasks"
import { localStorageKeys } from "../../../../../utils/constants/localStorageKeys"
import { rootPaths } from "../../../../../utils/constants/rootPaths"
import { isBrowser } from "../../../../../utils/helpers/isBrowser"
import { useLogout } from "../../../services/mutations"
import { layoutLang } from "../../../../../utils/language/layout"
import Link from "next/link"
import ReactInputMask from "react-input-mask"
import FavouriteIcon from "../../../../../assets/icons/FavouriteIcon"
import LocationIcon from "../../../../../assets/icons/LocationIcon"
import LoginIcon from "../../../../../assets/icons/LoginIcon"
import LogoutIcon from "../../../assets/icon/LogoutIcon"
import NonSSRWrapper from "../../../../../common/non-ssr/NonSSRWrapper"
import HeaderDropDown from "../header-dropdown/HeaderDropDown"

import style from "./HeaderTop.module.scss"

function HeaderTop() {
  const t = useTranslations("header.headerTop")
  const logout = useLogout()
  const router = useRouter()
  const { pathname, locale } = router
  const dispatch = useAppDispatch()
  const { setVisibleAddressModal, setVisibleLoginModal } = userReducerActions
  const { userAddress, isAuth } = useAppSelector((state) => state.userReducer)
  const setUserAddresBtnRef = useRef<HTMLButtonElement>(null)
  const [formattedPhone, setFormattedPhone] = useState(null)

  // is scroll state
  const [isScrollY0, setIsScrollY0] = useState<boolean>(false)

  // scroll set state
  if (isBrowser()) {
    window.addEventListener(eventTypes.SCROLL, () => {
      if (window.scrollY < 20) setIsScrollY0(false)
      else setIsScrollY0(true)
    })
  }

  // open login modal
  const openLoginModal = () => {
    dispatch(setVisibleLoginModal(true))
  }

  // open address modal
  const openAddressModal = () => {
    dispatch(setVisibleAddressModal(true))
  }

  // handle set user address
  const handleSetUserAddress = () => {
    setUserAddresBtnRef.current?.click()
  }

  // add event listener
  useEffect(() => {
    window.addEventListener(eventTypes.CLICK, handleSetUserAddress)

    return () => {
      window.removeEventListener(eventTypes.CLICK, handleSetUserAddress)
    }
  }, [])

  // active heart icon
  const activeHeartIcon = () => {
    if (pathname === rootPaths.FAVOURITES)
      return `${style.header_right_like} ${style.header_right_like_active}`
    else return style.header_right_like
  }

  // address
  const address = useMemo(() => {
    if (userAddress?.all) {
      return "Вся страна"
    }
    if (userAddress?.saved) {
      return userAddress?.city_name_ru
    }
    return layoutLang[String(locale)]["Ваш город"]
  }, [userAddress])

  // logout
  const handleLogout = () => {
    logout.mutate()
  }

  return (
    <div
      className={`${style.header} ${
        isScrollY0 ? style.header_active : ""
      } desktopD`}
      aria-hidden
    >
      <div className="wrapper d_f ai_c jc_sb">
        <div className={style.header_left}>
          <div
            className={style.header_left_location}
            onClick={openAddressModal}
            aria-hidden
          >
            <LocationIcon />
            <span suppressHydrationWarning>{address}</span>
          </div>
        </div>
        <div className={style.header_right}>
          <HeaderDropDown />
          <Tooltip
            placement="bottom"
            title={layoutLang[String(locale)]["Избранное"]}
          >
            <div className={activeHeartIcon()}>
              <Link href={rootPaths.FAVOURITES} legacyBehavior>
                <a aria-label="favorites">
                  <FavouriteIcon />
                </a>
              </Link>
            </div>
          </Tooltip>
          <NonSSRWrapper>
            <>
              {isAuth ? (
                <div className={style.header_right_login}>
                  <ReactInputMask
                    mask={PHONE_MASK}
                    value={localStorage.getItem(localStorageKeys.PHONE) ?? ""}
                    disabled
                    // @ts-ignore
                    ref={(e) => setFormattedPhone(e?.value)}
                  />
                  <Popover
                    trigger="click"
                    overlayClassName={style.popover}
                    content={
                      <div className={style.popover_content}>
                        {/*<Link href="https://dashboard.uysot.uz">*/}
                        {/*  <a>*/}
                        {/*    <AddIcon />*/}
                        {/*    <span>Подать объявление</span>*/}
                        {/*  </a>*/}
                        {/*</Link>*/}
                        <p onClick={handleLogout} aria-hidden>
                          {logout.isLoading ? (
                            <Spin
                              indicator={
                                <LoadingOutlined
                                  style={{ fontSize: 20 }}
                                  spin
                                />
                              }
                            />
                          ) : (
                            <LogoutIcon />
                          )}
                          <span>{t("Выйти")}</span>
                        </p>
                      </div>
                    }
                  >
                    <span>{formattedPhone}</span>
                  </Popover>
                </div>
              ) : (
                <div
                  className={style.header_right_login}
                  onClick={openLoginModal}
                >
                  <LoginIcon />
                  <span>
                    {layoutLang[String(locale)]["Войти или зарегистрироваться"]}
                  </span>
                </div>
              )}
            </>
          </NonSSRWrapper>
        </div>
      </div>
    </div>
  )
}

export default HeaderTop
