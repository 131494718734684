"use-client"
import { Grid } from "antd"
import { useRouter } from "next/router"
import React, { ReactNode, useEffect, useMemo } from "react"

import NonSSRWrapper from "../../common/non-ssr/NonSSRWrapper"
import { useAppSelector } from "../../hooks/reduxHooks"
import { rootPaths } from "../../utils/constants/rootPaths"
import AddressModal from "./components/address-modal/AddressModal"
import Footer from "./components/footer/Footer"
import Header from "./components/header/Header"
import LoginModal from "./components/login-modal/LoginModal"
import MobileAppDownload from "./components/mobile-app-download/MobileAppDownload"
import MobileMenu from "./components/mobile-menu/MobileMenu"
import { useGetFavourites } from "./services/queries"

import styles from "./index.module.scss"
import AppSuggestion from "../app-suggestion/Index"
import { isBrowser } from "utils/helpers/isBrowser"
import Notification from "./components/notification/Notification"
import { useCheckHasToken } from "services/useCheckHasToken"
import FooterFilter from "./components/footer-filter/FooterFilter"

type Props = {
  children: ReactNode
}

const MainLayout: React.FC<Props> = ({ children }) => {
  useGetFavourites()
  const router = useRouter()
  const { md } = Grid.useBreakpoint()
  const { userAddress } = useAppSelector((state) => state.userReducer)
  // is need footer
  const isNeedFooter = useMemo(
    () => router.pathname !== rootPaths.MAP,
    [router.pathname]
  )

  const hasToken = isBrowser() ? localStorage.getItem("token") : ""
  const getAnonimUserId = useCheckHasToken()

  useEffect(() => {
    if (!hasToken) {
      getAnonimUserId.mutateAsync().then((data) => {
        localStorage.setItem("anonymous_user_id", data?.data?.anonymous_user_id)
      })
    }
  }, [hasToken])

  return (
    <>
      <div className={styles.layout}>
        {/* <NonSSRWrapper> */}
        <div
          className={`${styles.distance} ${
            userAddress ? styles.has_address : ""
          }`}
        />
        {/* </NonSSRWrapper> */}
        <header className={styles.layout_header}>
          {/* <NonSSRWrapper> */}
          <Header />
          {/* </NonSSRWrapper> */}
        </header>
        {/* <NonSSRWrapper> */}
        {!md && <MobileMenu />}
        {/* </NonSSRWrapper> */}
        <main className={styles.layout_children}>{children}</main>
        {isNeedFooter && (
          <>
            <Notification />
            <FooterFilter />
            <MobileAppDownload />
            <Footer />
            <NonSSRWrapper>
              <AppSuggestion />
            </NonSSRWrapper>
          </>
        )}
      </div>
      <AddressModal />
      <LoginModal />
    </>
  )
}

export default MainLayout
