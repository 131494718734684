import React from "react"
import styles from "./footerFilter.module.scss"

import {
  useGetRegionCity,
  useGetRegionDistricts,
} from "pages/main-layout/services/queries"
import { useRouter } from "next/router"
import { layoutLang } from "utils/language/layout"
import { Collapse } from "antd"
import Link from "next/link"

const { Panel } = Collapse

const FooterFilter = () => {
  const { data } = useGetRegionDistricts(12, 1)
  const { data: regionCity } = useGetRegionCity()
  const { push, query, locale, pathname } = useRouter()

  const Cities = () => (
    <div className={styles.container_elements}>
      {data?.data?.map((item) => {
        return (
          <Link
            href={
              pathname?.includes("kvartiri")
                ? `/gorod-tashkent/kvartiri?region_id=12&district_id=${item?.id}`
                : `/gorod-tashkent/novostroyki?region_id=12&district_id=${item?.id}`
            }
            key={item?.id}
          >
            <p
              className={`${
                item?.id === Number(query?.district_id)
                  ? "text-[#086ADA]"
                  : "text-[#3A4252]"
              } text-base font-normal cursor-pointer`}
            >
              {locale === "uz"
                ? item?.name?.replace("tumani", "")
                : item?.name_ru?.replace("район", "")}
            </p>
          </Link>
        )
      })}
    </div>
  )

  const Regions = () => (
    <div className={styles.container_elements}>
      {regionCity?.data?.map((item) => {
        return (
          <Link
            href={
              pathname?.includes("kvartiri")
                ? `/gorod-tashkent/kvartiri?region_id=${item?.id}`
                : `/gorod-tashkent/novostroyki?region_id=${item?.id}`
            }
            key={item?.id}
          >
            <p
              className={`${
                item?.id === Number(query?.region_id)
                  ? "text-[#086ADA]"
                  : "text-[#3A4252]"
              } text-base font-normal cursor-pointer`}
            >
              {locale === "uz"
                ? item?.name?.replace("viloyati", "")
                : item?.name_ru?.replace("область", "")}
            </p>
          </Link>
        )
      })}
    </div>
  )

  return (
    <div className={styles.container}>
      <div className={styles.container_body}>
        <div>
          <h3 className="text-[#101828] text-xl font-semibold mb-6">
            {pathname?.includes("kvartiri")
              ? layoutLang[locale as keyof typeof layoutLang][
                  "Toshkentdagi_yangi_kvartiralar"
                ]
              : layoutLang[locale as keyof typeof layoutLang][
                  "Toshkentdagi_yangi_uylar"
                ]}
          </h3>
          <Cities />
        </div>
        <div>
          <h3 className="text-[#101828] text-xl font-semibold mb-6">
            {pathname?.includes("kvartiri")
              ? layoutLang[locale as keyof typeof layoutLang][
                  "Butun_Ozbekiston_boylab_kvartiralar"
                ]
              : layoutLang[locale as keyof typeof layoutLang][
                  "Butun_Ozbekiston_boylab_yangi_uylar"
                ]}
          </h3>
          <Regions />
        </div>
      </div>
      <div className={styles.container_bodyCollapse}>
        <Collapse
          expandIconPosition="end"
          bordered={false}
          defaultActiveKey={"1"}
        >
          <Panel
            className="bg-white mb-8"
            header={
              <h3 className="text-[#101828] text-xl font-semibold ">
                {pathname?.includes("kvartiri")
                  ? layoutLang[locale as keyof typeof layoutLang][
                      "Toshkentdagi_yangi_kvartiralar"
                    ]
                  : layoutLang[locale as keyof typeof layoutLang][
                      "Toshkentdagi_yangi_uylar"
                    ]}
              </h3>
            }
            key={"1"}
          >
            <Cities />
          </Panel>
          <Panel
            className="bg-white"
            header={
              <h3 className="text-[#101828] text-xl font-semibold ">
                {pathname?.includes("kvartiri")
                  ? layoutLang[locale as keyof typeof layoutLang][
                      "Butun_Ozbekiston_boylab_kvartiralar"
                    ]
                  : layoutLang[locale as keyof typeof layoutLang][
                      "Butun_Ozbekiston_boylab_yangi_uylar"
                    ]}
              </h3>
            }
            key={"2"}
          >
            <Regions />
          </Panel>
        </Collapse>
      </div>
    </div>
  )
}

export default FooterFilter
