import React from "react"

function LoginIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 1.66667H11.8333C9.16667 1.66667 7.5 3.33333 7.5 6V9.375H11.2L9.475 7.65C9.35 7.525 9.29167 7.36667 9.29167 7.20833C9.29167 7.05 9.35 6.89167 9.475 6.76667C9.71667 6.525 10.1167 6.525 10.3583 6.76667L13.15 9.55833C13.3917 9.8 13.3917 10.2 13.15 10.4417L10.3583 13.2333C10.1167 13.475 9.71667 13.475 9.475 13.2333C9.23333 12.9917 9.23333 12.5917 9.475 12.35L11.2 10.625H7.5V14C7.5 16.6667 9.16667 18.3333 11.8333 18.3333H13.9917C16.6583 18.3333 18.325 16.6667 18.325 14V6C18.3333 3.33333 16.6667 1.66667 14 1.66667Z"
        fill="#7C7C7C"
      />
      <path
        d="M2.29169 9.375C1.95002 9.375 1.66669 9.65833 1.66669 10C1.66669 10.3417 1.95002 10.625 2.29169 10.625H7.50002V9.375H2.29169Z"
        fill="#7C7C7C"
      />
    </svg>
  )
}

export default LoginIcon
