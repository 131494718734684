import React from "react"

const ArrowBottom = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.72003 5.93994L7.0667 10.2866C7.58003 10.7999 8.42003 10.7999 8.93336 10.2866L13.28 5.93994"
        stroke="#616161"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ArrowBottom
