import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { localStorageKeys } from "../../utils/constants/localStorageKeys"
import { sliceNames } from "../../utils/constants/sliceNames"
import { isBrowser } from "../../utils/helpers/isBrowser"
import { ApartmentModel } from "../../utils/models/apartmentModel"
import { NewResidentalComplexModel } from "../../utils/models/residentialComplexModel"
import queryString from "query-string"

// const query = isBrowser() && new URLSearchParams(window)
const pased: any = isBrowser() && queryString.parse(location.search)

type initialState = {
  complex: NewResidentalComplexModel[]
  apartment: ApartmentModel[]
  expoModal: boolean
  //for filter
  filter: {
    region_id: number | null
    district_id: number | null
    min_floor: number | null
    max_floor: number | null
    rooms_count: number[] | null
    repaired: boolean | null
    min_total_price: number | null
    max_total_price: number | null
    price_min_per_area: number | null
    price_max_per_area: number | null
    prepayment_min: number | null
    prepayment_max: number | null
    min_area: number | null
    max_area: number | null
    delay: string | null
    class_id: string | null
    deadline: string | null
    complex_name: string | null
  }
  windowPosition: {
    activeKey?: string | undefined
    positionY?: number
  }
  expoImg: string
}

const initialState: initialState = {
  complex: isBrowser()
    ? JSON.parse(localStorage.getItem(localStorageKeys.COMPLEX) || "[]")
    : [],
  apartment: isBrowser()
    ? JSON.parse(localStorage.getItem(localStorageKeys.APARTMENT) || "[]")
    : [],
  expoModal: true,
  //for filter
  filter: {
    class_id: pased?.class_id ? pased?.class_id : null,
    deadline: pased?.deadline ? pased?.deadline : null,
    delay: pased?.delay ? pased?.delay : null,
    district_id: pased?.district_id ? Number(pased?.district_id) : null,
    min_area: pased?.min_area ? Number(pased?.min_area) : null,
    max_area: pased?.max_area ? Number(pased?.max_area) : null,
    min_floor: pased?.min_floor ? Number(pased?.min_floor) : null,
    max_floor: pased?.max_floor ? Number(pased?.max_floor) : null,
    min_total_price: pased?.min_total_price
      ? Number(pased?.min_total_price)
      : null,
    max_total_price: pased?.max_total_price
      ? Number(pased?.max_total_price)
      : null,
    prepayment_max: pased?.prepayment_max
      ? Number(pased?.prepayment_max)
      : null,
    prepayment_min: pased?.prepayment_min
      ? Number(pased?.prepayment_min)
      : null,
    price_max_per_area: pased?.price_max_per_area
      ? Number(pased?.price_max_per_area)
      : null,
    price_min_per_area: pased?.price_min_per_area
      ? Number(pased?.price_min_per_area)
      : null,
    region_id: pased?.region_id ? Number(pased?.region_id) : null,
    repaired: pased?.repaired ? pased?.repaired : null,
    rooms_count: pased?.rooms_count ? pased?.rooms_count : null,
    complex_name: pased?.complex_name ? pased?.complex_name : "",
  },
  windowPosition: {
    activeKey: undefined,
    positionY: 0,
  },
  expoImg: "",
}

const favouritesReducer = createSlice({
  name: sliceNames.FAVOURITES,
  initialState,
  reducers: {
    appendApartment: (state, action: PayloadAction<ApartmentModel>) => {
      const newData = [...state.apartment, action.payload]

      state.apartment = newData
      localStorage.setItem(localStorageKeys.APARTMENT, JSON.stringify(newData))
    },
    removeApartment: (state, action: PayloadAction<number>) => {
      const newData = state.apartment.filter(
        (item) => item.apartment_id !== action.payload
      )

      state.apartment = newData
      localStorage.setItem(localStorageKeys.APARTMENT, JSON.stringify(newData))
    },
    setApartments: (state, action: PayloadAction<ApartmentModel[]>) => {
      const newData = action.payload

      state.apartment = newData
      localStorage.setItem(localStorageKeys.APARTMENT, JSON.stringify(newData))
    },
    appendComplex: (
      state,
      action: PayloadAction<NewResidentalComplexModel>
    ) => {
      const newData = [...state.complex, action.payload]

      state.complex = newData
      localStorage.setItem(localStorageKeys.COMPLEX, JSON.stringify(newData))
    },
    removeComplex: (state, action: PayloadAction<number>) => {
      const newData = state.complex.filter((item) => item.id !== action.payload)

      state.complex = newData
      localStorage.setItem(localStorageKeys.COMPLEX, JSON.stringify(newData))
    },
    setComplexes: (
      state,
      action: PayloadAction<NewResidentalComplexModel[]>
    ) => {
      const newData = action.payload

      state.complex = newData
      localStorage.setItem(localStorageKeys.COMPLEX, JSON.stringify(newData))
    },
    clearFavourites: (state) => {
      state.apartment = []
      state.complex = []
      localStorage.removeItem(localStorageKeys.APARTMENT)
      localStorage.removeItem(localStorageKeys.COMPLEX)
    },
    closeExpoModal: (state, action: PayloadAction<boolean>) => {
      state.expoModal = action.payload
    },
    setStudio: (state, action: PayloadAction<number[]>) => {
      state.filter.rooms_count = action.payload
    },
    setMinTotalPrice: (state, action: PayloadAction<number>) => {
      state.filter.min_total_price = action.payload
    },
    setMaxTotalPrice: (state, action: PayloadAction<number>) => {
      state.filter.max_total_price = action.payload
    },
    setMinAreaPrice: (state, action: PayloadAction<number>) => {
      state.filter.price_min_per_area = action.payload
    },
    setMaxAreaPrice: (state, action: PayloadAction<number>) => {
      state.filter.price_max_per_area = action.payload
    },
    setMinPrePayment: (state, action: PayloadAction<number>) => {
      state.filter.prepayment_min = action.payload
    },
    setMaxPrePayment: (state, action: PayloadAction<number>) => {
      state.filter.prepayment_max = action.payload
    },
    setChangeComplexName: (state, action: PayloadAction<string>) => {
      state.filter.complex_name = action.payload
    },
    setChangePrice: (
      state,
      action: PayloadAction<{
        type: "min_total_price" | "max_total_price"
        value: number
      }>
    ) => {
      const { type, value } = action.payload
      state.filter[type] = value
    },
    clearFilters: (state) => {
      state.filter.class_id = null
      state.filter.complex_name = ""
      ;(state.filter.deadline = null),
        (state.filter.delay = null),
        (state.filter.district_id = null),
        (state.filter.max_area = null),
        (state.filter.max_floor = null),
        (state.filter.max_total_price = null),
        (state.filter.min_area = null),
        (state.filter.min_floor = null),
        (state.filter.min_total_price = null),
        (state.filter.prepayment_max = null),
        (state.filter.prepayment_min = null),
        (state.filter.price_max_per_area = null),
        (state.filter.price_min_per_area = null),
        (state.filter.region_id = null),
        (state.filter.repaired = null),
        (state.filter.rooms_count = null)
    },
    setWindowPosition: (
      state,
      action: PayloadAction<{
        activeKey?: string | undefined
        positionY: number
      }>
    ) => {
      state.windowPosition.activeKey = action.payload.activeKey
      state.windowPosition.positionY = action.payload.positionY
    },
    setExpoImg: (state, action: PayloadAction<string>) => {
      state.expoImg = action.payload
    },
  },
})

export default favouritesReducer.reducer
export const favouritesReducerActions = favouritesReducer.actions
