import React from "react"

function LocationIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.1833 7.04167C16.3083 3.19167 12.95 1.45833 9.99996 1.45833C9.99996 1.45833 9.99996 1.45833 9.99162 1.45833C7.04996 1.45833 3.68329 3.18333 2.80829 7.03333C1.83329 11.3333 4.46662 14.975 6.84996 17.2667C7.73329 18.1167 8.86662 18.5417 9.99996 18.5417C11.1333 18.5417 12.2666 18.1167 13.1416 17.2667C15.525 14.975 18.1583 11.3417 17.1833 7.04167ZM9.99996 11.2167C8.54996 11.2167 7.37496 10.0417 7.37496 8.59167C7.37496 7.14167 8.54996 5.96667 9.99996 5.96667C11.45 5.96667 12.625 7.14167 12.625 8.59167C12.625 10.0417 11.45 11.2167 9.99996 11.2167Z"
        fill="#F23535"
      />
    </svg>
  )
}

export default LocationIcon
