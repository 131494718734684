export const endpoints = {
  REGISTER: "/register",
  LOGIN: "/login",
  LOCATION: "/location",
  ADDRESS: "/address",
  DISTRICTS: "/district",
  AUTH: "/auth",
  LOGOUT: "/logout",
  FAVOURITES: "/favorite",
  REFRESH: "/refresh",
  REGION_DISTRICTS: "/region/districts",
  CITY: "/region/list"
}
