import HeaderBottom from "./bottom/HeaderBottom"
import HeaderTopMobile from "./top-mobile/HeaderTopMobile"
import HeaderTop from "./top/HeaderTop"

import styles from "./header.module.scss"
import { useCheckAuth } from "../../services/queries"

const Header: React.FC = () => {
  // const { md } = Grid.useBreakpoint()
  useCheckAuth()

  return (
    <div className={styles.header}>
      <HeaderTop />
      <HeaderTopMobile />
      <HeaderBottom />
    </div>
  )
}

export default Header
