import React, { useRef, useState } from "react"
import { userReducerActions } from "../../../../store/reducers/userReducer"
import { useAppDispatch, useAppSelector } from "../../../../hooks/reduxHooks"
import { InputMask } from "../../../../common/inputMask/InputMask"
import { PHONE_MASK } from "../../../../utils/constants/inputMasks"
import { lengthValidator } from "../../../../utils/helpers/lengthValidator"
import { CallBackModalFormFields } from "../../utils/models/callBackModalModels"
import { useCallBack } from "../../services/mutations"
import { parsePhoneNumber } from "../../../../utils/helpers/parsePhoneNumber"
import { gaActions, gaCategories } from "../../../../utils/constants/ga"
import { useTranslations } from "next-intl"
import { recaptchaKey } from "../../../../utils/constants/recaptchaKey"
import { Button, Form, Input, Modal } from "antd"

import ReactGA from "react-ga4"
import CloseIcon from "../../../../assets/icons/CloseIcon"
import ReCAPTCHA from "react-google-recaptcha-enterprise"

import styles from "./callBackModal.module.scss"
import CallBackMessage from "../call-back-message/CallBackMessage"
import { useRouter } from "next/router"

const { Item } = Form

const CallBackModal: React.FC = () => {
  const { query, asPath } = useRouter()
  const t = useTranslations("common.CallBackModal")
  const [recaptchaToken, setRecapchaToken] = useState("")
  const callBack = useCallBack(handleAfterSuccess)
  const dispatch = useAppDispatch()
  const [formInstance] = Form.useForm()
  const { setCallBackModal, setIsClose } = userReducerActions
  const { callBackModal } = useAppSelector((state) => state.userReducer)
  const recaptchaRef = useRef<ReCAPTCHA | null>(null)

  const [openCallBack, setOpenCallBack] = useState(false)
  const ads = query?.utm_source === "ads"
  // close
  const handleClose = () => {
    dispatch(setCallBackModal({ visible: false, id: null, type: null }))
    setRecapchaToken("")
    dispatch(setIsClose(false))
  }

  // after close
  const handleAfterClose = () => {
    formInstance.resetFields()
  }

  // on finish
  const onFinish = (fields: CallBackModalFormFields) => {
    if (recaptchaToken) {
      callBack
        .mutateAsync({
          ...fields,
          phone: parsePhoneNumber(fields.phone) as string,
          [`${callBackModal.type}_id`]: callBackModal.id,
          recaptcha_token: recaptchaToken,
          url: window.location.href,
          ...(ads && { ads }),
        })
        .then(() => setOpenCallBack(true))
    } else {
      recaptchaRef.current?.execute()
    }
  }

  // after success
  function handleAfterSuccess() {
    handleClose()
    ReactGA.event({
      action: gaActions.CALL_ME_SUCCESS,
      category: gaCategories.PHONE_NUMBER,
    })
  }

  const handleHuman = (token: string | null) => {
    const values = {
      name: formInstance.getFieldValue("name"),
      phone: formInstance.getFieldValue("phone"),
    }

    if (token) {
      setRecapchaToken(token)
      callBack
        .mutateAsync({
          ...values,
          phone: parsePhoneNumber(values.phone) as string,
          [`${callBackModal.type}_id`]: callBackModal.id,
          recaptcha_token: token,
          url: window.location.href,
          ...(ads && { ads }),
        })
        .then(() => setOpenCallBack(true))
    }
  }

  return (
    <>
      <Modal
        centered
        title={null}
        footer={null}
        closable={false}
        onCancel={handleClose}
        open={callBackModal.visible}
        afterClose={handleAfterClose}
        className={`${styles.call_back_modal} custom_styles_modal`}
      >
        <div className="custom_styles_modal_header">
          <h1>{t("Обратная связь")}</h1>
          <CloseIcon onClick={handleClose} />
        </div>
        <Form
          layout="vertical"
          autoComplete="off"
          form={formInstance}
          onFinish={onFinish}
          className={styles.call_back_modal_body}
        >
          <Item name="name" rules={[{ required: true, message: "" }]}>
            <Input placeholder={t("Имя")} />
          </Item>
          <Item
            name="phone"
            rules={[{ required: true, message: "" }, lengthValidator(12)]}
          >
            {InputMask({ mask: PHONE_MASK, placeholder: t("Номер телефона") })}
          </Item>

          <ReCAPTCHA
            ref={recaptchaRef}
            style={{ display: "none" }}
            size="invisible"
            sitekey={recaptchaKey}
            onChange={handleHuman}
          />

          <Button type="primary" htmlType="submit" loading={callBack.isLoading}>
            {t("Продолжить")}
          </Button>
          {/* <p className={styles.description}>{t("description")}</p> */}
        </Form>
      </Modal>
      <CallBackMessage open={openCallBack} setOpen={setOpenCallBack} />
    </>
  )
}

export default CallBackModal
