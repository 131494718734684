import React from "react"

function UysotIcon() {
  return (
    <svg
      width="115"
      height="33"
      viewBox="0 0 115 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.28198 29.0127C3.28198 27.5399 4.48245 26.346 5.9633 26.346H25.6263C27.1072 26.346 28.3076 27.5399 28.3076 29.0127C28.3076 30.4855 27.1072 31.6794 25.6263 31.6794H5.9633C4.48245 31.6794 3.28198 30.4855 3.28198 29.0127Z"
        fill="#1B815F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.1144 2.11027C15.1558 1.05743 16.8442 1.05743 17.8856 2.11027L31.219 15.5901C32.2604 16.643 32.2604 18.35 31.219 19.4028C30.1776 20.4556 28.4891 20.4556 27.4477 19.4028L16 7.82929L4.55229 19.4028C3.51089 20.4556 1.82245 20.4556 0.781049 19.4028C-0.26035 18.35 -0.26035 16.643 0.781049 15.5901L14.1144 2.11027Z"
        fill="#1B815F"
      />
      <path
        d="M20.5129 19.167C20.5129 21.5461 18.4925 23.4747 16.0001 23.4747C13.5078 23.4747 11.4873 21.5461 11.4873 19.167C11.4873 16.7879 13.5078 14.8593 16.0001 14.8593C18.4925 14.8593 20.5129 16.7879 20.5129 19.167Z"
        fill="#FFAB55"
      />
      <path
        d="M47.8809 6.27148H44.8184V19.0684C44.8184 23.3203 47.8535 26.3281 52.8027 26.3281C57.752 26.3281 60.7871 23.3203 60.7871 19.0684V6.27148H57.7383V18.8086C57.7383 21.625 55.9473 23.6074 52.8027 23.6074C49.6582 23.6074 47.8809 21.625 47.8809 18.8086V6.27148ZM65.4902 31.3594C68.4844 31.3594 69.8926 30.2383 71.123 26.752L76.5234 11.6172H73.4062L69.7559 23.3203H69.7148L66.0645 11.6172H62.8652L68.0605 26.0137C68.0605 26.0273 67.8008 26.875 67.8008 26.9023C67.3496 28.3789 66.625 28.9531 65.2441 28.9531C64.998 28.9531 64.5059 28.9531 64.2871 28.8984V31.291C64.5059 31.332 65.2578 31.3594 65.4902 31.3594ZM78 15.6914C78 17.7012 79.3125 19.0547 81.9102 19.6836L84.4805 20.3125C85.9844 20.6953 86.5312 21.2012 86.5312 22.1172C86.5312 23.2793 85.4375 24.0176 83.6602 24.0176C81.8555 24.0176 80.7891 23.252 80.5566 21.8711H77.6309C77.8633 24.5098 80.1191 26.2734 83.5234 26.2734C87.0508 26.2734 89.498 24.4688 89.498 21.8438C89.498 19.7246 88.4043 18.6309 85.5059 17.9336L83.0859 17.373C81.5957 17.0039 80.8848 16.4023 80.8848 15.5137C80.8848 14.3789 81.9512 13.5996 83.5508 13.5996C85.2051 13.5996 86.2578 14.4062 86.3945 15.7051H89.1699C89.0742 13.1484 86.832 11.3574 83.6328 11.3574C80.2695 11.3574 78 13.1211 78 15.6914ZM97.9746 26.2734C102.09 26.2734 104.797 23.4434 104.797 18.8086C104.797 14.1875 102.076 11.3574 97.9746 11.3574C93.873 11.3574 91.1523 14.1875 91.1523 18.8086C91.1523 23.4434 93.8594 26.2734 97.9746 26.2734ZM97.9746 23.8398C95.6641 23.8398 94.1602 22.0078 94.1602 18.8086C94.1602 15.623 95.6641 13.791 97.9746 13.791C100.285 13.791 101.789 15.623 101.789 18.8086C101.789 22.0078 100.299 23.8398 97.9746 23.8398ZM107.941 8.21289V11.6172H105.85V13.9551H107.941V22.2129C107.941 24.9883 109.076 26.0957 111.988 26.0957C112.645 26.0957 113.301 26.041 113.629 25.959V23.6211C113.438 23.6621 112.959 23.6895 112.658 23.6895C111.455 23.6895 110.908 23.1289 110.908 21.8848V13.9551H113.643V11.6172H110.908V8.21289H107.941Z"
        fill="#1B815F"
      />
    </svg>
  )
}

export default UysotIcon
