import { useMutation } from "@tanstack/react-query"
import axios from "axios"

export function useCheckHasToken() {
  return useMutation(async () => {
    const res = await axios.post(
      "https://marble.oci.softex.uz/main/anonymous_user/create",
      { withCredentials: true }
    )

    return res.data
  })
}
