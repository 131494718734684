import { useTranslations } from "next-intl"
import Link from "next/link"
import { useRouter } from "next/router"
import React, { useEffect, useMemo } from "react"

import ArrowRightIcon from "../../../../assets/icons/ArrowRightIcon"
import LocationIcon from "../../../../assets/icons/LocationIcon"
import { useAppDispatch, useAppSelector } from "../../../../hooks/reduxHooks"
import { localeReducerActions } from "../../../../store/reducers/localeReducer"
import { userReducerActions } from "../../../../store/reducers/userReducer"
import { RU, UZ } from "../../../../utils/constants/locales"
import { menuLinks } from "../../utils/constants/menuLinks"

import styles from "./mobileMenu.module.scss"
import NonSSRWrapper from "../../../../common/non-ssr/NonSSRWrapper"
import { layoutLang } from "../../../../utils/language/layout"

const MobileMenu: React.FC = () => {
  const t = useTranslations("header.menuLinks")
  const dispatch = useAppDispatch()
  const { setVisibleAddressModal, setVisibleMobileMenu, setChangeMenu } =
    userReducerActions
  const { visibleMobileMenu, userAddress, menuIcon } = useAppSelector(
    (state) => state.userReducer
  )
  const router = useRouter()
  const { locale } = router
  const { changeLanguage } = localeReducerActions
  //distrcit_id
  const district_id = useMemo(() => userAddress?.district_id, [userAddress])

  // location
  const location = useMemo(() => userAddress?.url_name, [userAddress])

  // open address modal
  const handleOpenAddressModal = () => {
    dispatch(setVisibleAddressModal(true))
  }

  // close mobile menu
  const handleCloseMobileMenu = () => {
    dispatch(setVisibleMobileMenu(false))
    dispatch(setChangeMenu(!menuIcon))
  }

  useEffect(() => {
    dispatch(changeLanguage(locale === RU ? RU : UZ))
  }, [])

  return (
    <div
      className={`${styles.mobile_menu} ${
        visibleMobileMenu ? styles.active : ""
      } ${userAddress ? styles.has_address : ""}`}
    >
      <div className={styles.content}>
        <div
          className={`${styles.content_header} ${
            visibleMobileMenu ? styles.active : ""
          }`}
        >
          <div className={styles.left}>
            <LocationIcon />
            <NonSSRWrapper>
              <span>{userAddress?.city_name_ru}</span>
            </NonSSRWrapper>
          </div>
          <div className={styles.right}>
            <span onClick={handleOpenAddressModal} aria-hidden>
              {t("Изменить")}
            </span>
          </div>
        </div>
        <div
          className={`${styles.content_body} ${
            visibleMobileMenu ? styles.active : ""
          }`}
        >
          <p className={styles.title}>{t("Меню")}</p>
          <nav>
            <ul className={styles.menu}>
              {menuLinks(location, district_id).map((item, index) => (
                <li key={index}>
                  <Link href={item.path} legacyBehavior>
                    <a onClick={handleCloseMobileMenu} aria-hidden>
                      <span>
                        {item.label === "EXPO 2024"
                          ? item.label
                          : layoutLang[String(locale) as any][item.label]}
                      </span>
                      <ArrowRightIcon />
                    </a>
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        </div>
        {/* <div className={styles.header_right_lang}>
          <Button
            type={locale === UZ ? "primary" : "default"}
            // className={locale === UZ ? styles.active_lang : ""}
            onClick={() => handleCurrentLang(UZ)}
          >
            <Link href={asPath} locale={UZ}>
              Uz
            </Link>
          </Button>
          <Button
            type={locale === RU ? "primary" : "default"}
            // className={locale === RU ? styles.active_lang : ""}
            onClick={() => handleCurrentLang(RU)}
          >
            <Link
              href={asPath}
              className={locale === RU ? "active_lang" : ""}
              locale={RU}
            >
              Ру
            </Link>
          </Button>
        </div> */}
      </div>
    </div>
  )
}

export default MobileMenu
