import { useMutation } from "@tanstack/react-query"
import { useAppDispatch } from "../../../hooks/reduxHooks"
import $api from "../../../services/RequestService"
import { favouritesReducerActions } from "../../../store/reducers/favouritesReducer"
import { userReducerActions } from "../../../store/reducers/userReducer"
import { localStorageKeys } from "../../../utils/constants/localStorageKeys"
import { messageTypes } from "../../../utils/constants/messageTypes"
import { errorHandler } from "../../../utils/helpers/errorHandler"
import { showMessage } from "../../../utils/helpers/showMessage"
import { ErrorRes, SuccessRes } from "../../../utils/models/responseType"
import { endpoints } from "../utils/constants/apis"
import { DistrictType } from "../utils/models/districtModel"

// register
export function useRegister(afterSuccess: () => void) {
  return useMutation<SuccessRes, ErrorRes, { phone: string }>(
    async (req) => {
      const res = await $api.post(endpoints.REGISTER, req)
      return res.data
    },
    {
      onSuccess: (res) => {
        showMessage(res?.messages, messageTypes.SUCCESS)
        afterSuccess()
      },
      onError: errorHandler,
    },
  )
}

// login
export function useLogin(afterSuccess: () => void) {
  const dispatch = useAppDispatch()
  const { setIsAuth } = userReducerActions

  return useMutation<
    SuccessRes<{ phone: string }>,
    ErrorRes,
    { phone: string; sms_code: number }
  >(
    async (req) => {
      const res = await $api.post(endpoints.LOGIN, req)
      return res.data
    },
    {
      onSuccess: (res) => {
        localStorage.setItem(localStorageKeys.PHONE, res?.phone)
        dispatch(setIsAuth(true))
        afterSuccess()
      },
      onError: errorHandler,
    },
  )
}

// logout
export function useLogout() {
  const dispatch = useAppDispatch()
  const { setIsAuth } = userReducerActions
  const { clearFavourites } = favouritesReducerActions

  return useMutation(
    async () => {
      const res = await $api.get(endpoints.LOGOUT)
      return res.data
    },
    {
      onSuccess: () => {
        dispatch(setIsAuth(false))
        dispatch(clearFavourites())
      },
      onError: errorHandler,
    },
  )
}

// refresh token
export function useRefreshToken() {
  const logout = useLogout()

  return useMutation(
    async () => {
      const res = await $api.get(endpoints.REFRESH)
      return res.data
    },
    {
      onError: () => {
        logout.mutate()
      },
    },
  )
}

// set user address
export function useSetUserAddress(afterSuccess: () => void) {
  const dispatch = useAppDispatch()
  const { setUserAddress } = userReducerActions

  return useMutation<DistrictType, ErrorRes, { city_id?: number, district_id?: number, all?: boolean }>(
    async (req) => {
      const res = await $api.post(endpoints.ADDRESS, {
        city_id: req?.city_id,
        district_id: req?.district_id,
        all: req?.all,
      })
      return res.data
    },
    {
      onSuccess: (res, req) => {
        afterSuccess()
        if (req?.all) {
          localStorage.setItem(
            localStorageKeys.ADDRESS,
            JSON.stringify({ ...res, saved: true, all: true }),
          )
          dispatch(setUserAddress({ ...res, saved: true, all: true }))
        } else {
          localStorage.setItem(
            localStorageKeys.ADDRESS,
            JSON.stringify({ ...res, saved: true }),
          )
          dispatch(setUserAddress({ ...res, saved: true }))
        }
      },
    },
  )
}


