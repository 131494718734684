import React, { useState } from "react"
import styles from "./Index.module.scss"
import CloseIcon from "../../assets/icons/CloseIcon"
import { Button } from "antd"
import { isAndroid, isMobile } from "react-device-detect"
import IconImage from "../../assets/images/LogoInAppSuggestion.png"
import Image from "next/image"
import { useTranslations } from "next-intl"
// ...

const AppSuggestion: React.FC = () => {
  const [isCanceled, setIsCanceled] = useState(false)
  const t = useTranslations("appSuggestion")
  const installApp = () => {
    if (isAndroid) {
      window.location.href =
        "https://play.google.com/store/apps/details?id=uz.uysot.marketplace&utm_source=uysot_uz&utm_campaign=footer_link&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
    }
  }
  const cancelSmartbanner = () => {
    setIsCanceled(true)
  }
  if (isAndroid && isMobile) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className={isCanceled ? styles.canceled : styles.container}>
          <div className={styles.logoAndInfoContainer}>
            <CloseIcon
              className={styles.cancelBtn}
              onClick={cancelSmartbanner}
            />
            <div className={styles.iconImage}>
              <Image src={IconImage} alt="Icon" />
            </div>
            <div className={styles.info}>
              <h2>Uysot Marketplace</h2>
              <h5>{t("Рынок_новых_домов")}</h5>
            </div>
          </div>
          <Button
            className={styles.installAppBtn}
            type="primary"
            color="green"
            onClick={installApp}
          >
            {t("Скачать")}
          </Button>
        </div>
      </div>
    )
  } else {
    return null
  }
}

export default AppSuggestion
